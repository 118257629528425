import { useEffect, useState, useRef } from "react";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";

import SignatureCanvas from "react-signature-canvas";

import { Input } from "src/shadcn/ui/input";
import { Label } from "src/shadcn/ui/label";
import { useUser } from "src/hooks";
import { IReferenceCheck } from "src/models";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "src/shadcn/ui/card";

import ReferenceCheck from "./ReferenceCheck";
import { Textarea } from "src/shadcn/ui/textarea";
import ShowSignature from "./ShowSignature";

interface Props {
  referenceList: IReferenceCheck[];
}

const Reference = ({ referenceList }: Props) => {
  const { data: user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentReferenceId = searchParams.get("referenceId");
  const step = searchParams.get("step");

  // refs
  const signatureCanvasRef = useRef<SignatureCanvas | null>(null);

  // states
  const [currentReference, setCurrentReference] = useState<IReferenceCheck | null>(null);

  // effects
  useEffect(() => {
    if (!currentReferenceId && referenceList && referenceList?.length > 0 && step === "7") {
      navigate(`${location.pathname}?step=7&referenceId=${referenceList[0].details.id}`);
      return;
    }

    if (currentReferenceId && referenceList && referenceList?.length > 0) {
      const currentReference = referenceList.find((reference) => reference.details.id === currentReferenceId);
      setCurrentReference(currentReference ?? null);
    }
  }, [currentReferenceId, referenceList]);

  useEffect(() => {
    if (currentReference) {
      const sign = currentReference?.candidate_consent?.signature ?? "";
      if (sign) {
        signatureCanvasRef.current?.fromDataURL(sign);
      }
    }
  }, [currentReference]);

  return (
    <>
      <div>
        <p className="text-xl font-bold mb-2">Reference Check</p>
        {currentReference && <ReferenceCheck currentReference={currentReference} />}
        {currentReference?.candidate_consent?.signature && (
          <div className="mt-8 mb-2">
            <p className="text-sm font-bold">Candidate's consent</p>
            <Card className="mt-2">
              <CardHeader className="hidden">
                <CardTitle>dddd</CardTitle>
                <CardDescription>asdasd</CardDescription>
              </CardHeader>
              <CardContent className="p-4">
                <p className="text-sm mb-2">
                  Hi <strong>{currentReference?.details?.recruitment_lead}</strong>
                </p>
                <p className="text-sm mb-1">
                  I,&nbsp;
                  <strong>
                    {currentReference?.candidate?.first_name} {currentReference?.candidate?.last_name}
                  </strong>
                  , here by give my consent to <strong>{user?.first_name}</strong> to contact the{" "}
                  <strong>{currentReference?.referees?.length}</strong> referees I have provided for the purpose of
                  conducting a reference check related to my application for the position <strong>{"Job Title"}</strong>
                  .
                </p>
                <p className="text-sm mb-1">
                  I understand that the information I provide will be used solely for the purpose of evaluating the
                  candidate for employment and will be kept confidential.
                </p>
                <p className="text-sm mb-2">Please feel free to contact me at your convenience.</p>
                <p className="text-sm mb-1">Sincerely,</p>

                <SignatureCanvas
                  ref={signatureCanvasRef}
                  penColor="green"
                  canvasProps={{ width: 350, height: 150, className: "sigCanvas" }}
                  backgroundColor="#cfe1b9"
                />
              </CardContent>
            </Card>
          </div>
        )}
        {currentReference?.referee_consents && currentReference?.referee_consents?.length > 0 && (
          <div className="mt-8 mb-2">
            <p className="text-sm font-bold">Referee Check</p>
            <Card className="mt-2">
              <CardHeader className="hidden">
                <CardTitle>dddd</CardTitle>
                <CardDescription>asdasd</CardDescription>
              </CardHeader>
              <CardContent className="p-4 space-y-10">
                {currentReference?.referees?.map((referee, index) => {
                  return (
                    <div
                      key={`referee-${index}`}
                      className="border-b border-slate-300 pb-4"
                    >
                      <p className="text-sm font-bold text-center">Reference {index + 1}</p>
                      <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                        <div>
                          <Label className="text-xs">Full name</Label>
                          <Input
                            disabled
                            value={`${referee?.first_name} ${referee?.last_name}`}
                          />
                        </div>

                        <div>
                          <Label className="text-xs">Phone</Label>
                          <Input
                            disabled
                            value={referee?.mobile}
                          />
                        </div>

                        <div>
                          <Label className="text-xs">Email</Label>
                          <Input
                            disabled
                            value={referee?.mobile}
                          />
                        </div>

                        <div>
                          <Label className="text-xs">Link profile</Label>
                          <Input
                            disabled
                            value={referee?.mobile}
                          />
                        </div>

                        <div className="col-span-2">
                          <Label className="text-xs">Professional relationship with referee</Label>
                          <Textarea
                            disabled
                            value={referee?.relation}
                          />
                        </div>

                        <div>
                          <Label className="text-xs">Start working with referee</Label>
                          <Input
                            disabled
                            value={referee?.start_date}
                          />
                        </div>

                        <div>
                          <Label className="text-xs">End working with referee</Label>
                          <Input
                            disabled
                            value={referee?.end_date}
                          />
                        </div>

                        <div>
                          <Label className="text-xs">Company's name</Label>
                          <Input
                            disabled
                            value={referee?.company_name}
                          />
                        </div>

                        <div>
                          <Label className="text-xs">Referee's job</Label>
                          <Input
                            disabled
                            value={referee?.job_name}
                          />
                        </div>

                        {currentReference?.details?.questions && currentReference?.details?.questions?.length > 0 && (
                          <div className="col-span-2">
                            <Label className="text-xs">Response from referee</Label>

                            <div className="space-y-2">
                              {currentReference?.details?.questions.map((question, index) => (
                                <div
                                  key={`${currentReference?.details?.id}-${index}`}
                                  className="bg-slate-200 p-2 rounded-md"
                                >
                                  <Label className="text-xs">
                                    <strong>Question {index + 1}: </strong>
                                    {question}
                                  </Label>
                                  <Textarea
                                    disabled
                                    value={referee?.answers?.[index]}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>

                      <p className="text-sm font-bold text-center mt-8">{`Referee ${index + 1}'s consent`}</p>
                      <p className="text-sm mb-2">
                        Hi <strong>{currentReference?.details?.recruitment_lead}</strong>
                      </p>
                      <p className="text-sm mb-1">
                        I,&nbsp;
                        <strong>
                          {referee?.first_name} {referee?.last_name}
                        </strong>
                        , here by give my consent to <strong>{user?.first_name}</strong> to contact the{" "}
                        <strong>{currentReference?.referees?.length}</strong> referees I have provided for the purpose
                        of conducting a reference check related to my application for the position{" "}
                        <strong>{"Job Title"}</strong>.
                      </p>
                      <p className="text-sm mb-1">
                        I understand that the information I provide will be used solely for the purpose of evaluating
                        the candidate for employment and will be kept confidential.
                      </p>
                      <p className="text-sm mb-2">Please feel free to contact me at your convenience.</p>
                      <p className="text-sm mb-1">Sincerely,</p>

                      <ShowSignature signature={currentReference?.referee_consents[index]?.signature} />
                    </div>
                  );
                })}
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

export default Reference;
