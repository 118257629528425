import { handleGlobalError } from "src/lib/utils";

import api from "../api";
import { ICandidateAndRound, IInterviewRound, IJob, IListReport, IReport, IResume, IResumeRound } from "src/models";

export const createJobFirstStep = async (
  title?: string,
  employer?: string,
  ref?: string,
  note?: string,
  teamId?: string,
): Promise<IJob | undefined> => {
  try {
    const res = await api.post(`/jobs`, {
      title,
      employer,
      ref,
      note,
      team_id: teamId,
    });

    return res.data?.job;
  } catch (error) {
    handleGlobalError(error, teamId);
    return undefined;
  }
};

export const updateJobFirstStep = async (
  jobId: string,
  updates: {
    title?: string;
    employer?: string;
    ref?: string;
    note?: string;
    team_id?: string;
    employer_email?: string;
    recruitment_lead?: string;
    company_logo?: string;
  },
) => {
  try {
    const filteredUpdates = Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      Object.entries(updates).filter(([_, value]) => value !== undefined),
    );

    const { data } = await api.patch(`/jobs/${jobId}`, filteredUpdates);
    return data?.job;
  } catch (error) {
    handleGlobalError(error, updates.team_id);
    return undefined;
  }
};

export const getJobs = async (teamId: string): Promise<IJob[] | undefined> => {
  try {
    const res = await api.get(`/jobs?team_id=${teamId}`);
    return res.data?.jobs || [];
  } catch (error) {
    handleGlobalError(error, teamId);
    return undefined;
  }
};

export const addDescriptionToJob = async (jobId: string, description: string): Promise<IJob | undefined> => {
  try {
    const res = await api.patch(`/jobs/${jobId}/description`, { description });
    return res.data?.job;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const addFileToJob = async (file: File, jobId: string): Promise<IJob | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const res = await api.post(`/jobs/${jobId}/description/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data?.job;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getJobById = async (id: string): Promise<IJob | undefined> => {
  try {
    const res = await api.get(`/jobs/${id}`);
    return res.data?.job;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const uploadResumeFile = async (jobId: string, files: File[], team_id: string) => {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append("files", file);
  });

  try {
    const res = await api.post(`/jobs/${jobId}/resumes`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data?.job;
  } catch (error) {
    handleGlobalError(error, team_id);
    return undefined;
  }
};

export const deleteResumeFile = async (jobId: string, resumeId: string) => {
  try {
    const res = await api.delete(`/jobs/${jobId}/resumes/${resumeId}`);
    return res.data?.job || {};
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const assessmentCriteriaGenerate = async (jobId: string) => {
  try {
    const res = await api.post(`/jobs/${jobId}/assessment/generate`);
    return res.data?.job;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const updateAssessmentCriteria = async (jobId: string, mustHave: string[], desireHave: string[]) => {
  try {
    const res = await api.patch(`/jobs/${jobId}/assessment`, {
      assessment_must_have_criteria: mustHave,
      assessment_desire_to_have_criteria: desireHave,
    });
    return res.data?.job;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const generateListReport = async (jobId: string): Promise<IReport | undefined> => {
  try {
    const res = await api.post(`/jobs/${jobId}/report/generate`);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return;
  }
};

export const generateInterview = async (jobId: string) => {
  try {
    const res = await api.post(`/jobs/${jobId}/interview_qa/generate`);
    return res.data?.job;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getShortListReport = async (jobId: string) => {
  try {
    const res = await api.get(`/jobs/${jobId}/shortlist-report`);
    return res.data?.report || [];
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getReport = async (jobId: string): Promise<IReport | undefined> => {
  try {
    const res = await api.get(`/jobs/${jobId}/report`);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const generateInsight = async (jobId: string, question: string) => {
  try {
    const res = await api.post(`/jobs/${jobId}/insight`, { question });
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const exportJobReport = async (jobId: string, report: IListReport[]) => {
  try {
    const res = await api.post(
      `/jobs/${jobId}/report/export`,
      { report },
      {
        responseType: "arraybuffer",
      },
    );
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const deleteJobById = async (jobId: string) => {
  try {
    const res = await api.delete(`/jobs/${jobId}`);

    if (res.status === 200) {
      return "success";
    }

    return "failed";
  } catch (error) {
    handleGlobalError(error);
    return "error";
  }
};

export const generateChatReportStream = async () => {};

export const getResumeFiles = async (jobId: string): Promise<IResume[] | undefined> => {
  try {
    const res = await api.get(`/jobs/${jobId}/resumes`);
    return res.data?.resumes || [];
  } catch (error) {
    return undefined;
  }
};

export const getInterviewRounds = async (jobId: string): Promise<IInterviewRound[]> => {
  try {
    const res = await api.get(`/jobs/${jobId}/interview_rounds`);
    return res.data?.rounds || [];
  } catch (error) {
    return [];
  }
};

export const createInterviewRound = async (jobId: string): Promise<IInterviewRound | undefined> => {
  try {
    const res = await api.post(`/jobs/${jobId}/interview_rounds`);
    return res.data?.round;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getListCandidateByRoundId = async (
  jobId: string,
  roundId: string,
): Promise<IResumeRound[] | undefined> => {
  try {
    const res = await api.get(`/jobs/${jobId}/interview_rounds/${roundId}/resumes`);
    return res.data?.resumes ?? [];
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getListCandidateByRoundIdReturnMoreRound = async (
  jobId: string,
  roundId: string,
  currentRoundNumber: number,
  currentRound: IInterviewRound,
): Promise<ICandidateAndRound | undefined> => {
  try {
    const res1 = await api.get(`/jobs/${jobId}/interview_rounds/${roundId}/resumes`);
    const res2 = await api.get(`/jobs/${jobId}/interview_rounds/${roundId}/interviews`);

    return {
      roundNumber: currentRoundNumber,
      candidates: res1.data?.resumes ?? [],
      round: currentRound,
      contents: res2.data?.interviews ?? [],
    };
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const generateResumesShortlist = async (resume_ids: string[], jobId: string): Promise<IResume[]> => {
  try {
    const res = await api.post(`/jobs/${jobId}/resumes/shortlist`, {
      resume_ids,
    });
    return res.data?.resumes ?? [];
  } catch (error) {
    handleGlobalError(error);
    return [];
  }
};

export const uploadInterviewFilePerResume = async (
  jobId: string,
  roundId: string,
  resumeId: string,
  file?: File,
  content?: string,
) => {
  try {
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }

    if (content) {
      formData.append("input", JSON.stringify({ content: content }));
    }

    const res = await api.post(
      `/jobs/${jobId}/interview_rounds/${roundId}/resumes/${resumeId}/interviews/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const updateInterviewFilePerResume = async (
  jobId: string,
  roundId: string,
  resumeId: string,
  interviewId: string,
  file?: File,
) => {
  try {
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }

    const res = await api.patch(
      `/jobs/${jobId}/interview_rounds/${roundId}/resumes/${resumeId}/interviews/${interviewId}/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const addNewContentPerInterview = async (jobId: string, roundId: string, resumeId: string, content: string) => {
  try {
    const res = await api.post(`/jobs/${jobId}/interview_rounds/${roundId}/resumes/${resumeId}/interviews`, {
      content,
    });
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const updateContentPerInterview = async (
  jobId: string,
  roundId: string,
  resumeId: string,
  interviewId: string,
  content: string,
) => {
  try {
    const res = await api.patch(
      `/jobs/${jobId}/interview_rounds/${roundId}/resumes/${resumeId}/interviews/${interviewId}/content`,
      {
        content,
      },
    );
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const setFinalizeJobInterviewRound = async (jobId: string, roundId: string) => {
  try {
    const res = await api.post(`/jobs/${jobId}/interview_rounds/${roundId}/finalize`);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getInterviewPerRound = async (jobId: string, roundId: string) => {
  try {
    const res = await api.get(`/jobs/${jobId}/interview_rounds/${roundId}/interviews`);
    return res.data;
  } catch (error) {
    return undefined;
  }
};

export const shortlistInterviewPerRound = async (jobId: string, roundId: string, interviewCommentIds: string[]) => {
  try {
    const res = await api.post(`/jobs/${jobId}/interview_rounds/${roundId}/shortlist`, {
      interview_ids: interviewCommentIds,
    });
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};
