import { useTheme } from "src/shadcn/components/theme-provider";

const cleanPercentage = (percentage: number) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct }: { colour: string; pct: number }) => {
  const r = 30; // Reduced from 40 to 30 to match new container size
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;

  return (
    <circle
      r={r}
      cx={40} // Reduced from 50 to 40 to match new container size
      cy={40} // Reduced from 50 to 40 to match new container size
      fill="transparent"
      stroke={strokePct !== circ ? colour : "#d1d5db"}
      strokeWidth="0.6rem"
      strokeLinecap="round"
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
    ></circle>
  );
};

const Text = ({ percentage }: { percentage: number }) => {
  const { theme } = useTheme();

  return (
    <text
      x="50%"
      y="45%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize="0.7em"
      fontWeight="700" // Increased font weight for bolder text
      fill={theme === "dark" ? "white" : "black"}
      style={{ fontWeight: 900 }} // Added style prop for extra boldness
    >
      {percentage.toFixed(0)}%
    </text>
  );
};

const PercentCircle = ({ percentage, colour }: { percentage: number; colour: string }) => {
  const pct = cleanPercentage(percentage);
  return (
    <svg
      width={80}
      height={80}
    >
      <g transform={`rotate(-90 ${"40 40"})`}>
        <Circle
          colour="#d1d5db"
          pct={0}
        />
        <Circle
          colour={colour}
          pct={pct}
        />
      </g>
      <Text percentage={pct} />
      <text
        x="50%"
        y="60%"
        dominantBaseline="central"
        textAnchor="middle"
        fontSize="0.6em"
        // fill={theme === "dark" ? "white" : "black"}
        style={{ fontWeight: 600 }}
      >
        Match
      </text>
    </svg>
  );
};

export default PercentCircle;
