import { Loader2 } from "lucide-react";

import { cn } from "src/lib/utils";
import { Button } from "src/shadcn/ui/button";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  isLoading?: boolean;
  className?: string;
  textClassName?: string;
}

const ButtonLoading: React.FC<Props> = ({ isLoading, text = "Submit", className, textClassName, ...props }) => {
  return (
    <Button
      disabled={isLoading}
      className={cn("flex items-center gap-1", className)}
      {...props}
    >
      {isLoading && <Loader2 className="size-4 animate-spin" />}
      <p className={cn("text-sm", textClassName)}>{text}</p>
    </Button>
  );
};

export default ButtonLoading;
