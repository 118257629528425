import { Loader2 } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import { Dispatch, SetStateAction, useState, useRef, useMemo, useEffect } from "react";

import { Label } from "src/shadcn/ui/label";
import { Button } from "src/shadcn/ui/button";
import { Switch } from "src/shadcn/ui/switch";
import { Slider } from "src/shadcn/ui/slider";
import { baseURL } from "src/services/api";
import { Checkbox } from "src/shadcn/ui/checkbox";
import { Progress } from "src/shadcn/ui/progress";
import { cn, moveToDivById } from "src/lib/utils";
import { ICandidateAndRound, IJob, IListReport, IReport, IResume } from "src/models";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "src/shadcn/ui/card";
import { generateResumesShortlist, getReport, createInterviewRound, exportJobReport } from "src/services/job";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "src/shadcn/ui/table";

import PercentCircle from "src/components/common/PercentCircle";

interface Props {
  currentJob: IJob;
  selectedCandidate: string[] | null;
  candidatesAndRounds: ICandidateAndRound[] | null;
  resumeFiles: IResume[];
  setCurrentJob?: Dispatch<SetStateAction<IJob | null>>;
  setSelectedCandidate: Dispatch<SetStateAction<string[] | null>>;
  callBackAfterGenerateShortList?: () => void;
}

const CandidateShortListReport: React.FC<Props> = ({
  currentJob,
  selectedCandidate,
  candidatesAndRounds,
  resumeFiles,
  setSelectedCandidate,
  callBackAfterGenerateShortList,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  // refs
  const generateEventSourceRef = useRef<EventSource | null>(null);

  // states
  const [isLoading, setIsLoading] = useState(false);
  const [listReportOrigin, setListReportOrigin] = useState<IReport>({
    short_list_report: [],
    full_list_report: [],
  });
  const [matchScore, setMatchScore] = useState([40]);
  const [isShortList, setIsShortList] = useState(false);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [isExportingReport, setIsExportingReport] = useState(false);
  const [candidateView, setCandidateView] = useState<string>("");

  // functions
  const handleSortReportByScore = (reportList: IListReport[]) => {
    return reportList.sort((a, b) => b.score - a.score) ?? [];
  };

  const handleGenerateResumesShortlist = async () => {
    const createNewFirstRound = !candidatesAndRounds || (candidatesAndRounds && candidatesAndRounds.length === 0);

    setIsLoading(true);
    const res1 = await generateResumesShortlist(selectedCandidate ?? [], currentJob.id);
    if (createNewFirstRound) {
      await createInterviewRound(currentJob.id);
    }
    setIsLoading(false);

    if (res1) {
      callBackAfterGenerateShortList?.();
      navigate(`${location.pathname}?step=6&roundNumber=0`);
    }
  };

  const handleGetReport = async () => {
    setIsLoading(true);
    const res = await getReport(currentJob.id);
    setIsLoading(false);

    if (res) {
      setListReportOrigin({ ...res });
    }
  };

  const handleGenerateReport = async () => {
    if (generateEventSourceRef.current) {
      generateEventSourceRef.current.close();
    }

    setIsGeneratingReport(true);
    setListReportOrigin({
      short_list_report: [],
      full_list_report: [],
    });
    generateEventSourceRef.current = new EventSource(
      `${baseURL}/jobs/${currentJob.id}/report/generate/stream?token=${localStorage.getItem("access_token")}`,
    );

    generateEventSourceRef.current.onmessage = (e) => {
      if (e.data === "DONE") {
        setIsGeneratingReport(false);
        generateEventSourceRef.current?.close();
        return;
      }

      if (e.data) {
        try {
          setIsGeneratingReport(true);
          const dataJson = JSON.parse(e.data);
          const data = JSON.parse(dataJson);

          if (data?.short_list) {
            setListReportOrigin((prev) => {
              return {
                ...prev,
                short_list_report: [...prev.short_list_report, data.short_list],
              };
            });
          } else if (data?.full_list) {
            setListReportOrigin((prev) => {
              return {
                ...prev,
                full_list_report: [...prev.full_list_report, data.full_list],
              };
            });
          }
        } catch (error) {
          console.error(error);
          setIsGeneratingReport(false);
        }
      }
    };

    generateEventSourceRef.current.onerror = () => {
      setIsGeneratingReport(false);
      generateEventSourceRef.current?.close();
    };
  };

  const handleCheckedChange = (value: boolean) => {
    setIsShortList(value);
  };

  const handleCheckedProceed = (checked: boolean | "indeterminate", report: IListReport) => {
    const resumeId = report?.resume_id;

    if (!report) {
      return;
    }

    if (checked) {
      setSelectedCandidate((prev) => {
        return [...(prev ?? []), resumeId];
      });
    } else {
      setSelectedCandidate((prev) => {
        return (prev ?? []).filter((item) => item !== resumeId);
      });
    }
  };

  const handleNextStep = () => {
    handleGenerateResumesShortlist();
  };

  const handleBack = () => {
    navigate(`${location.pathname}?step=4`);
  };

  const handleExportReport = async () => {
    let report: IListReport[] = [];

    if (isShortList) {
      report = listReport?.short_list_report || [];
    } else {
      report = listReport?.full_list_report || [];
    }

    setIsExportingReport(true);
    const res = await exportJobReport(currentJob.id, report);
    setIsExportingReport(false);

    if (res) {
      const blob = new Blob([res], {
        type: "application/pdf",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "report.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
  };

  // memos
  const listReport = useMemo(() => {
    const currentScore = 100 - matchScore[0];
    const fullListReportByScore =
      (listReportOrigin?.full_list_report ?? []).filter((report) => report.score >= currentScore) ?? [];

    return {
      short_list_report: listReportOrigin?.short_list_report ?? [],
      full_list_report: fullListReportByScore ?? [],
    };
  }, [matchScore, listReportOrigin]);

  const currentProgress = useMemo(() => {
    if (!isGeneratingReport) {
      return {
        progress: 0,
        total: 0,
      };
    }

    if (resumeFiles && resumeFiles?.length > 0) {
      const totalResumes = resumeFiles?.length ?? 0;
      const totalShortList = listReportOrigin?.full_list_report?.length ?? 0;

      return {
        progress: Math.round((totalShortList / totalResumes) * 100),
        total: totalShortList,
      };
    }

    return {
      progress: 0,
      total: 0,
    };
  }, [listReportOrigin, currentJob, isLoading]);

  // effects
  useEffect(() => {
    handleGetReport();
  }, []);

  return (
    <div className="w-full grow relative pb-14">
      <div>
        <div className="flex justify-between items-center">
          <p className="text-xl font-bold">Candidate Shortlist Report</p>
          <div className="flex items-center space-x-2">
            <Label
              className={cn("text-sm", isShortList && "text-foreground/45")}
              htmlFor="short-full-report"
            >
              All candidates
            </Label>
            <Switch
              id="short-full-report"
              defaultChecked={isShortList}
              onCheckedChange={handleCheckedChange}
            />
            <Label
              className={cn("text-sm", !isShortList && "text-foreground/45")}
              htmlFor="short-full-report"
            >
              Display only shortlisted
            </Label>
          </div>
        </div>
        <div className="mt-4 flex-1">
          <div className="flex items-center justify-between mb-6">
            <p className="text-sm">
              {isShortList
                ? "The following candidates meet all “must have”, and all or some “desirable” requirements:"
                : "Detailed assessment report for all candidates:"}
            </p>
            <div className={cn("hidden flex-col gap-2", !isShortList && "flex")}>
              <div className="flex items-center gap-2">
                <div className="w-[150px]">
                  <Label>Filter by match score:</Label>
                </div>
                <Slider
                  defaultValue={[40]}
                  value={matchScore}
                  max={100}
                  step={1}
                  className="w-[250px]"
                  onValueChange={(value) => setMatchScore(value)}
                  dir="rtl"
                />
                <Label>100%</Label>
              </div>
              <p className={cn("text-sm mt-4", isGeneratingReport && "hidden")}>
                {listReport?.full_list_report?.length ?? 0} out of {listReportOrigin?.full_list_report?.length ?? 0}
                &nbsp; candidates match
              </p>
            </div>
          </div>

          {isGeneratingReport && (
            <div className="w-full">
              <span className="text-sm font-semibold">
                Assessing {currentProgress?.total} out of {resumeFiles?.length} resumes
              </span>
              <div className="w-full max-w-[700px] flex items-center gap-2 mx-auto">
                <span className="text-sm font-semibold">0%</span>
                <div className="flex-1">
                  <Progress
                    className="h-2 bg-foreground/45"
                    value={currentProgress?.progress ?? 0}
                  />
                </div>
                <span className="text-sm font-semibold">100%</span>
              </div>
            </div>
          )}

          {!isLoading &&
            // !isGeneratingReport &&
            ((isShortList && listReport?.short_list_report?.length > 0) ||
              (!isShortList && listReport?.full_list_report?.length > 0)) && (
              <div className="w-full flex gap-8 mt-4">
                <div className={cn("space-y-4 grow", !isShortList && "hidden")}>
                  {listReport?.short_list_report && listReport?.short_list_report?.length > 0 && (
                    <>
                      {listReport?.short_list_report.map((report, index) => (
                        <Card
                          key={`full-list-report-${report.email}-${index}`}
                          id={`short-list-candidate-${report.resume_id}`}
                          className={cn("p-3 py-5")}
                        >
                          <CardHeader className="hidden">
                            <CardTitle>Interview Questions</CardTitle>
                            <CardDescription>Short list description</CardDescription>
                          </CardHeader>
                          <CardContent className="p-0">
                            <div className="mb-3 flex flex-col md:flex-row md:justify-between p-2 rounded-tl-md rounded-tr-md bg-coralGlow">
                              <div>
                                <p className="text-sm font-semibold dark:text-black">
                                  Candidate name:&nbsp;
                                  {report.first_name} {report.last_name}
                                </p>
                                <p className="text-sm font-semibold dark:text-black">
                                  Contact number:&nbsp;
                                  {report.mobile}
                                </p>
                              </div>
                              <div>
                                <p className="text-sm font-semibold dark:text-black">
                                  Email:&nbsp;
                                  {report.email}
                                </p>
                              </div>
                            </div>
                            <div className="flex items-end justify-between space-x-2 mb-6">
                              <div className="grow flex gap-2">
                                <div className="shrink-0">
                                  <PercentCircle
                                    percentage={report?.score}
                                    colour={
                                      report?.score >= 70
                                        ? "#22c55e"
                                        : report?.score >= 50 && report?.score < 70
                                        ? "orange"
                                        : "red"
                                    }
                                  />
                                </div>
                                <div className="">
                                  <p className="text-sm">{report?.assessment_summary}</p>
                                </div>
                              </div>
                            </div>

                            <Table key={`short-list-${report.email}-${index}`}>
                              <TableCaption className="hidden">A list of your recent invoices.</TableCaption>
                              <TableHeader>
                                <TableRow className="bg-foreground/30">
                                  <TableHead className="">Requirements</TableHead>
                                  <TableHead>Type</TableHead>
                                  <TableHead>Match</TableHead>
                                  <TableHead>Details</TableHead>
                                </TableRow>
                              </TableHeader>
                              <TableBody className="">
                                {((report?.must_have && report.must_have?.length > 0) ||
                                  (report?.desire_to_have && report?.desire_to_have?.length > 0)) &&
                                  [...report.must_have, ...report.desire_to_have].map((item, i) => (
                                    <TableRow key={`short-list-items-${index}-${i}`}>
                                      <TableCell>{item.criteria}</TableCell>
                                      <TableCell>Must have</TableCell>
                                      <TableCell>{item.satisfied}</TableCell>
                                      <TableCell>{item.details}</TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </CardContent>
                        </Card>
                      ))}
                    </>
                  )}
                </div>

                <div className={cn("space-y-4 grow", isShortList && "hidden")}>
                  {listReport?.full_list_report && listReport?.full_list_report?.length > 0 && (
                    <>
                      {listReport?.full_list_report.map((report, index) => {
                        const isSelected = !!(selectedCandidate ?? []).find((item) => item === report.resume_id);

                        return (
                          <Card
                            key={`full-list-report-${report.email}-${index}`}
                            id={`full-list-candidate-${report.resume_id}`}
                            className={cn("p-3 py-5")}
                          >
                            <CardHeader className="hidden">
                              <CardTitle>Interview Questions</CardTitle>
                              <CardDescription>Short list description</CardDescription>
                            </CardHeader>
                            <CardContent className="p-0">
                              <div className="flex items-center justify-end gap-2 shrink-0 mb-2">
                                <Checkbox
                                  id={`proceed-to-interview-${report.resume_id}`}
                                  checked={isSelected}
                                  onCheckedChange={(checked: boolean | "indeterminate") =>
                                    handleCheckedProceed(checked, report)
                                  }
                                  className="data-[state=checked]:bg-green-500 data-[state=checked]:text-primary-foreground data-[state=checked]:border-green-500 transition-all"
                                />
                                <label
                                  htmlFor={`proceed-to-interview-${report.resume_id}`}
                                  className={cn(
                                    "text-sm font-bold leading-none cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
                                    isSelected && "text-green-500",
                                  )}
                                >
                                  Proceed to interview
                                </label>
                              </div>
                              <div
                                className={cn(
                                  "mb-3 flex flex-col md:flex-row md:justify-between p-2 rounded-tl-md rounded-tr-md bg-coralGlow transition-all duration-300",
                                  isSelected && "bg-green-500 text-white",
                                )}
                              >
                                <div className="">
                                  <p className="text-sm font-semibold dark:text-black">
                                    Candidate name:&nbsp;
                                    {report.first_name} {report.last_name}
                                  </p>
                                  <p className="text-sm font-semibold dark:text-black">
                                    Contact number:&nbsp;
                                    {report.mobile}
                                  </p>
                                </div>
                                <div>
                                  <p className="text-sm font-semibold dark:text-black">
                                    Email:&nbsp;
                                    {report.email}
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-end justify-between space-x-2 mb-6">
                                <div className="grow flex gap-2">
                                  <div className="shrink-0">
                                    <PercentCircle
                                      percentage={report?.score}
                                      colour={
                                        report?.score >= 70
                                          ? "#22c55e"
                                          : report?.score >= 50 && report?.score < 70
                                          ? "orange"
                                          : "red"
                                      }
                                    />
                                  </div>
                                  <div className="">
                                    <p className="text-sm text-justify">{report?.assessment_summary}</p>
                                  </div>
                                </div>
                              </div>

                              <Table key={`short-list-${report.email}-${index}`}>
                                <TableCaption className="hidden">A list of your recent invoices.</TableCaption>
                                <TableHeader>
                                  <TableRow className="bg-foreground/30">
                                    <TableHead className="">Requirements</TableHead>
                                    <TableHead>Type</TableHead>
                                    <TableHead>Match</TableHead>
                                    <TableHead>Details</TableHead>
                                  </TableRow>
                                </TableHeader>
                                <TableBody className="">
                                  {((report?.must_have && report.must_have?.length > 0) ||
                                    (report?.desire_to_have && report?.desire_to_have?.length > 0)) &&
                                    [...report.must_have, ...report.desire_to_have].map((item, i) => (
                                      <TableRow key={`short-list-items-${index}-${i}`}>
                                        <TableCell>{item.criteria}</TableCell>
                                        <TableCell>Must have</TableCell>
                                        <TableCell>{item.satisfied}</TableCell>
                                        <TableCell>{item.details}</TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </CardContent>
                          </Card>
                        );
                      })}
                    </>
                  )}
                </div>

                <div className="shrink-0 w-[250px] max-h-[70vh] sticky top-14 overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 [&::-webkit-scrollbar-thumb]:rounded-full hover:[&::-webkit-scrollbar-thumb]:bg-gray-400">
                  <div
                    className={cn(
                      "min-h-[40vh] h-fit rounded-lg border bg-card p-4",
                      isShortList && listReport?.short_list_report?.length <= 0 && "hidden",
                      !isShortList && listReport?.full_list_report?.length <= 0 && "hidden",
                    )}
                  >
                    <p className="text-sm font-semibold">View by candidate</p>
                    {!isShortList && (
                      <ul className="space-y-2 pl-6">
                        {handleSortReportByScore(listReport?.full_list_report).map((report, index) => (
                          <li
                            key={`full-list-candidate-${report.resume_id}-${index}`}
                            className={cn(
                              "list-decimal cursor-pointer hover:bg-gray-100 rounded p-2 transition-colors",
                              candidateView === `full-list-candidate-${report.resume_id}` && "bg-gray-100",
                            )}
                            onClick={() => {
                              setCandidateView(`full-list-candidate-${report.resume_id}`);
                              moveToDivById(`full-list-candidate-${report.resume_id}`);
                            }}
                          >
                            <div>
                              <p className="text-sm font-medium line-clamp-1">
                                {`${report.first_name} ${report.last_name}`}
                              </p>
                              <p className="text-sm text-foreground/45 line-clamp-1">{report.email}</p>
                              <p className="text-sm text-foreground/45 line-clamp-1">{`${report.score}% match`}</p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}

                    {isShortList && (
                      <ul className="space-y-2 pl-6">
                        {handleSortReportByScore(listReport?.short_list_report).map((report, index) => (
                          <li
                            key={`short-list-candidate-${report.resume_id}-${index}`}
                            className={cn(
                              "list-decimal cursor-pointer hover:bg-gray-100 rounded p-2 transition-colors",
                              candidateView === `short-list-candidate-${report.resume_id}` && "bg-gray-100",
                            )}
                            onClick={() => {
                              setCandidateView(`short-list-candidate-${report.resume_id}`);
                              moveToDivById(`short-list-candidate-${report.resume_id}`);
                            }}
                          >
                            <div>
                              <p className="text-sm font-medium line-clamp-1">
                                {`${report.first_name} ${report.last_name}`}
                              </p>
                              <p className="text-sm text-foreground/45 line-clamp-1">{report.email}</p>
                              <p className="text-sm text-foreground/45 line-clamp-1">{`${report.score}% match`}</p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            )}

          {isLoading && (
            <div className="h-[40vh] flex items-center justify-center">
              <Loader2 className="size-10 text-green-500 animate-spin" />
            </div>
          )}

          {!isLoading &&
            !isGeneratingReport &&
            ((isShortList && listReport?.short_list_report?.length === 0) ||
              (!isShortList && listReport?.full_list_report?.length === 0)) && (
              <div className="h-[40vh] flex items-center justify-center">
                <p className="text-sm">
                  Click <strong>"Generate Report"</strong> to assess and shortlist candidates
                </p>
              </div>
            )}
        </div>
      </div>
      <div className="absolute bottom-0 right-0 flex justify-end gap-4">
        <Button
          variant="outline"
          onClick={handleBack}
        >
          Back
        </Button>

        <Button
          className="flex items-center gap-1"
          disabled={isExportingReport || isLoading || isGeneratingReport}
          onClick={handleExportReport}
        >
          {isExportingReport && <Loader2 className="size-4 animate-spin" />}
          <span>Export Report</span>
        </Button>

        <Button
          className="flex items-center gap-1"
          disabled={isLoading || isGeneratingReport || isExportingReport}
          onClick={handleGenerateReport}
        >
          {isGeneratingReport && <Loader2 className="size-4 animate-spin" />}
          <span>Generate Report</span>
        </Button>

        <Button
          className="flex items-center gap-1"
          disabled={isLoading || isGeneratingReport || isExportingReport}
          onClick={handleNextStep}
        >
          {isLoading && <Loader2 className="size-4 animate-spin" />}
          <span>Next Step</span>
        </Button>
      </div>
    </div>
  );
};

export default CandidateShortListReport;
