import { Trash } from "lucide-react";

import React, { useState, useEffect } from "react";

import { DeleteDialog } from "src/components/common";
import { deleteResumeFile } from "src/services/job";
import { cn, getResumeFileName } from "src/lib/utils";
import { IResume, IUploadResumeFileType } from "src/models";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/shadcn/ui/tooltip";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/shadcn/ui/table";
import { Badge } from "src/shadcn/ui/badge";

interface Props {
  teamId: string;
  jobId: string;
  resumes: IResume[];
  currentSocketEvent: IUploadResumeFileType | null;
  className?: string;
  callBack?: () => void;
}

const UploadResumesTable: React.FC<Props> = ({ teamId, jobId, resumes, currentSocketEvent, className, callBack }) => {
  // states
  const [resumeFiles, setResumeFiles] = useState<IResume[]>(resumes);

  // functions
  const handleDeleteResume = async (id: string) => {
    const res = await deleteResumeFile(jobId, id);

    if (res) {
      callBack && callBack();
    }
  };

  // effects
  useEffect(() => {
    if (currentSocketEvent && currentSocketEvent.type === "Resume") {
      setResumeFiles((prev) => {
        if (!prev) return [];

        return prev.map((p) => {
          if (p?.id === currentSocketEvent?.item?.id) {
            return {
              ...p,
              status: currentSocketEvent?.item?.status,
            };
          }

          return p;
        });
      });
    }
  }, [currentSocketEvent]);

  useEffect(() => {
    if (resumes && resumes?.length > 0) {
      setResumeFiles([...resumes]);
    }
  }, [resumes]);

  return (
    <div className={cn("border rounded-md overflow-hidden", className && className)}>
      <Table>
        <TableHeader className="bg-gray-300">
          <TableRow>
            <TableHead className="w-[100px] font-bold text-black">Resumes</TableHead>
            <TableHead className="font-bold text-black">File type</TableHead>
            <TableHead className="font-bold text-black">Status</TableHead>
            <TableHead className="font-bold text-black">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {resumeFiles?.length === 0 && (
            <TableRow>
              <TableCell
                colSpan={5}
                className="text-center text-sm text-gray-500"
              >
                No resumes found
              </TableCell>
            </TableRow>
          )}
          {resumeFiles &&
            resumeFiles?.length > 0 &&
            resumeFiles.map((file) => (
              <TableRow key={file?.id}>
                <TableCell className="font-medium text-nowrap">{getResumeFileName(file?.file_name, teamId)}</TableCell>
                <TableCell>{file.file_type}</TableCell>
                <TableCell>
                  <Badge variant={file?.status === "Indexed" ? "activate" : "cancelled"}>
                    {file?.status === "Indexed" ? "Ready" : "Processing"}
                  </Badge>
                </TableCell>
                <TableCell>
                  <div className="w-fit">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div>
                            <DeleteDialog
                              description="This action cannot be undone. This will permanently delete this resume file."
                              onAction={() => handleDeleteResume(file?.id)}
                            >
                              <Trash className="size-4" />
                            </DeleteDialog>
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p className="text-xs dark:text-white text-black">Delete</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default UploadResumesTable;
