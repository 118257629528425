import { z } from "zod";
import { useForm } from "react-hook-form";
import { Dispatch, SetStateAction, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SendHorizontal } from "lucide-react";

import { IJob } from "src/models";
import { Button } from "src/shadcn/ui/button";
import { Textarea } from "src/shadcn/ui/textarea";
import { ScrollArea } from "src/shadcn/ui/scroll-area";
import { generateInsight } from "src/services/job";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "src/shadcn/ui/form";

import ChatItem from "./ChatItem";

interface Props {
  currentJob: IJob;
  setCurrentJob: Dispatch<SetStateAction<IJob | null>>;
}

const chatSchema = z.object({
  question: z.string().nonempty("Question is required"),
});

const ChatBox = ({ currentJob, setCurrentJob }: Props) => {
  // states
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof chatSchema>>({
    resolver: zodResolver(chatSchema),
    defaultValues: {
      question: "",
    },
  });

  // functions
  const handleGenerateInsight = async (question: string) => {
    const currentInsights = currentJob.insights || [];
    const newInsightNoAnswer = [...currentInsights, { question, answer: "" }];

    setCurrentJob((prev) => {
      if (!prev) {
        return null;
      }

      return { ...prev, insights: newInsightNoAnswer };
    });

    form.reset();

    setIsLoading(true);
    const res = await generateInsight(currentJob.id, question);
    setIsLoading(false);

    if (res?.answer) {
      newInsightNoAnswer[newInsightNoAnswer.length - 1].answer = res.answer;

      setCurrentJob((prev) => {
        if (!prev) {
          return null;
        }

        return { ...prev, insights: newInsightNoAnswer };
      });
    }
  };

  const onsubmit = (values: z.infer<typeof chatSchema>) => {
    handleGenerateInsight(values.question);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      form.handleSubmit(onsubmit)();
    }
  };

  return (
    <div className="h-[calc(100vh-320px)] flex flex-col gap-2">
      <ScrollArea className="flex-1 whitespace-pre-wrap text-sm border rounded-sm p-2">
        {currentJob.insights &&
          currentJob.insights?.length > 0 &&
          currentJob.insights.map((insight, index) => (
            <ChatItem
              key={`chat-result-item-${index}`}
              currentChat={insight}
            />
          ))}
      </ScrollArea>
      <div className="">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onsubmit)}>
            <div className="flex items-end gap-2 border border-input rounded-md p-2">
              <FormField
                control={form.control}
                name="question"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormControl>
                      <Textarea
                        placeholder="Ask and get further insights from resumes"
                        {...field}
                        className="p-0 border-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0"
                        onKeyDown={(e) => handleKeyDown(e)}
                      />
                    </FormControl>
                    <FormMessage className="text-xs" />
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                variant="outline"
                size="icon"
                disabled={isLoading}
                className="rounded-full"
              >
                <SendHorizontal className="size-4" />
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default ChatBox;
