import { z } from "zod";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";

import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { Textarea } from "src/shadcn/ui/textarea";
import { ButtonLoading } from "src/components/common";
import { IReferenceCheck } from "src/models";
import { sendReferenceCheckToCandidate } from "src/services/references";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "src/shadcn/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/shadcn/ui/select";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "src/shadcn/ui/form";

interface Props {
  currentReference: IReferenceCheck;
}

const EXTRA_QUESTIONS = [
  "Example “What are the three strengths the candidate has?”",
  "Example “What are the candidate's three areas for development?”",
];

const formSchema = z.object({
  recruitmentLead: z.string(),
  email: z.string(),
  employer: z.string(),
  companyLogo: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  phone: z.string(),
  candidateEmail: z.string(),
  linkedinProfile: z.string().optional(),
  questionList: z.array(z.string()),
  numberRefereesRequired: z.string(),
});

const ReferenceCheck: React.FC<Props> = ({ currentReference }) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      recruitmentLead: "",
      email: "",
      employer: "",
      companyLogo: "",
      firstName: "",
      lastName: "",
      phone: "",
      candidateEmail: "",
      linkedinProfile: "",
      questionList: ["", ""],
      numberRefereesRequired: "0",
    },
  });

  // states
  const [isSending, setIsSending] = useState(false);

  // functions
  const handleSendReferenceCheckToCandidate = async (values: z.infer<typeof formSchema>) => {
    setIsSending(true);
    const res = await sendReferenceCheckToCandidate(
      currentReference.details.job_id,
      currentReference?.details?.id,
      values.questionList,
      Number(values.numberRefereesRequired),
      {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.candidateEmail,
        mobile: values.phone,
        profile_url: values.linkedinProfile ?? "",
      },
    );
    setIsSending(false);

    if (res) {
      toast.success("Reference request sent successfully");
    }
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    handleSendReferenceCheckToCandidate(values);
  };

  // effects
  useEffect(() => {
    if (currentReference) {
      form.setValue("recruitmentLead", currentReference.details.recruitment_lead ?? "");
      form.setValue("email", currentReference.details.employer_email ?? "");
      form.setValue("employer", currentReference.details.employer ?? "");
      form.setValue("firstName", currentReference?.candidate?.first_name ?? "");
      form.setValue("lastName", currentReference?.candidate?.last_name ?? "");
      form.setValue("phone", currentReference?.candidate?.mobile ?? "");
      form.setValue("candidateEmail", currentReference?.candidate?.email ?? "");
      form.setValue("linkedinProfile", currentReference?.candidate?.profile_url ?? "");
      form.setValue("numberRefereesRequired", `${currentReference?.details?.referee_count || 1}`);

      const questionList = currentReference?.details?.questions || ["", ""];
      form.setValue("questionList", questionList);
    }
  }, [currentReference]);

  return (
    <Card className="p-2">
      <CardHeader className="p-0">
        <CardTitle className="text-sm text-foreground text-center mb-4 hidden">Employer details</CardTitle>
        <CardDescription className="hidden">
          This is your reference check for the candidate. Please fill in the details below.
        </CardDescription>
      </CardHeader>
      <CardContent className="p-0">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <p className="text-sm font-semibold text-center mb-2">Employer details</p>
            <div className="w-full grid grid-cols-2 gap-x-8 gap-y-4">
              <div>
                <FormField
                  control={form.control}
                  name="recruitmentLead"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">Recruitment Lead</FormLabel>
                      <FormControl>
                        <Input
                          disabled
                          placeholder="Enter your recruitment lead"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div>
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">Email</FormLabel>
                      <FormControl>
                        <Input
                          disabled
                          placeholder="Enter your email"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div>
                <FormField
                  control={form.control}
                  name="employer"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">Employer</FormLabel>
                      <FormControl>
                        <Input
                          disabled
                          placeholder="Enter your employer"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex items-end gap-2">
                <div>
                  {currentReference?.details?.company_logo ? (
                    <div className="w-10 h-10 border border-slate-200 rounded-full p-1 overflow-hidden">
                      <img
                        src={currentReference?.details?.company_logo}
                        alt={currentReference?.details?.employer}
                        className="w-full h-auto object-contain"
                      />
                    </div>
                  ) : (
                    <div className="w-10 h-10 bg-slate-200 rounded-full flex items-center justify-center">
                      {currentReference?.details?.employer_email?.split("@")?.[0]?.[0]?.toUpperCase()}
                    </div>
                  )}
                </div>

                <div className="h-10 flex items-center">
                  <p className="text-xs font-semibold">{currentReference?.details?.employer || "Company Logo"}</p>
                </div>
              </div>
            </div>

            <p className="mt-6 mb-2 text-sm font-semibold text-center">Candidates details</p>
            <div className="w-full grid grid-cols-2 gap-x-8 gap-y-4">
              <div>
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">First Name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter first name"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div>
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">Last Name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter last name"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div>
                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">Phone</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter phone"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div>
                <FormField
                  control={form.control}
                  name="candidateEmail"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">Candidate Email</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter candidate email"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div>
                <FormField
                  control={form.control}
                  name="linkedinProfile"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">LinkedIn Profile</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter LinkedIn profile"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div />

              <div>
                <FormField
                  control={form.control}
                  name="numberRefereesRequired"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-xs">Number Referees Required</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value.toString()}
                      >
                        <FormControl>
                          <SelectTrigger
                            style={{
                              marginTop: 0,
                            }}
                          >
                            <SelectValue placeholder="Select a verified email to display" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="0">0</SelectItem>
                          <SelectItem value="1">1</SelectItem>
                          <SelectItem value="2">2</SelectItem>
                        </SelectContent>
                      </Select>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="col-span-2 space-y-2">
                {form.watch("questionList").map((_, index) => (
                  <div
                    key={index}
                    className="bg-slate-200 p-2 rounded-md"
                  >
                    <FormField
                      control={form.control}
                      name={`questionList.${index}`}
                      render={({ field }) => (
                        <FormItem className="space-y-1">
                          <FormLabel className="text-xs">Question {index + 1}</FormLabel>
                          <FormControl>
                            <Textarea
                              placeholder={EXTRA_QUESTIONS?.[index] || `Enter question ${index + 1}`}
                              {...field}
                              style={{ marginTop: 0 }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-between mt-4">
              <Button
                type="button"
                className="bg-mintGreen text-white hover:bg-mintGreen/80 transition-all"
                onClick={() => {
                  form.setValue("questionList", [...form.watch("questionList"), ""]);
                }}
              >
                Add more questions
              </Button>
              <ButtonLoading
                type="submit"
                isLoading={isSending}
                text="Send reference request"
                textClassName="font-semibold"
              />
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};

export default ReferenceCheck;
