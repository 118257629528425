import { useRef, useEffect } from "react";

import SignatureCanvas from "react-signature-canvas";

interface Props {
  signature: string;
}

const ShowSignature = ({ signature }: Props) => {
  const signatureCanvasRef = useRef<SignatureCanvas | null>(null);

  useEffect(() => {
    if (signatureCanvasRef.current) {
      signatureCanvasRef.current.fromDataURL(signature);
    }
  }, [signature]);

  return (
    <SignatureCanvas
      ref={signatureCanvasRef}
      penColor="green"
      canvasProps={{ width: 350, height: 150, className: "sigCanvas" }}
      backgroundColor="#cfe1b9"
    />
  );
};

export default ShowSignature;
