import { Loader2 } from "lucide-react";
import React, { useState } from "react";

import { Button } from "src/shadcn/ui/button";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "src/shadcn/ui/alert-dialog";

interface Props {
  children: React.ReactNode;
  title?: string;
  description?: string;
  onAction?: () => void;
}

const DeleteDialog = ({ children, title, description, onAction }: Props) => {
  // state
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // functions
  const handleAction = async () => {
    if (onAction) {
      setIsLoading(true);
      await onAction();
      setOpenModal(false);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Button
        variant="ghost"
        className="p-1 h-fit"
        onClick={() => setOpenModal(true)}
      >
        {children}
      </Button>
      <AlertDialog
        open={openModal}
        onOpenChange={() => setOpenModal(false)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{title || "Are you absolutely sure?"}</AlertDialogTitle>
            <AlertDialogDescription>
              {description || "This action cannot be undone. This will permanently delete your team from our servers."}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button
              className="flex items-center gap-1"
              disabled={isLoading}
              onClick={() => {
                handleAction();
              }}
            >
              {isLoading && <Loader2 className="animate-spin size-4" />}
              <span>Confirm</span>
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </React.Fragment>
  );
};

export default DeleteDialog;
