import { Plus } from "lucide-react";
import { Table } from "@tanstack/react-table";
import { getTeams } from "src/services/team";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

import { Input } from "src/shadcn/ui/input";
import { ITeam } from "src/models";
import { Button } from "src/shadcn/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/shadcn/ui/select";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

export function DataTableToolbar<TData>({ table }: DataTableToolbarProps<TData>) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const defaultTeamId = searchParams.get("team") || "";

  // states
  const [teams, setTeams] = useState<ITeam[]>([]);

  // functions
  const handleGetTeams = async () => {
    const res = await getTeams();

    if (res?.length > 0) {
      setTeams(res);
      const defaultTeam = res.find((r) => r.is_default);

      if (defaultTeam && !defaultTeamId) {
        navigate(`${location.pathname}?team=${defaultTeam.id}`);
      }
    }
  };

  const handleChangeTeam = (value: string) => {
    navigate(`${location.pathname}?team=${value}`);
  };

  // effects
  useEffect(() => {
    // if (!location.search.includes("team")) {
    handleGetTeams();
  }, [location]);

  // memo
  const currentCols = table
    .getAllColumns()
    .map((column) => ({
      col: column.id,
      val: (column?.getFilterValue() as string) ?? "",
    }))
    .filter((c) => ["ref", "title", "employer"].includes(c.col));
  const currentSearch = currentCols.map((c) => c.val).find((c) => c.length > 0) ?? "";

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <div className="flex items-center gap-4">
          <Input
            placeholder="Filter jobs..."
            value={currentSearch}
            onChange={(event) => table.getColumn("title")?.setFilterValue(event.target.value)}
            className="h-10 w-[200px] lg:w-[300px]"
          />
          <Select
            value={defaultTeamId}
            onValueChange={handleChangeTeam}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select a team" />
            </SelectTrigger>

            <SelectContent>
              {teams.map((team) => (
                <SelectItem
                  key={team.id}
                  value={team.id}
                >
                  {team.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex gap-3 justify-start md:justify-end w-full">
          <Button
            variant="ghost"
            className="bg-[#1ED760] font-medium text-white hover:text-white transform hover:bg-[#21e065] transition-colors duration-200"
            onClick={() => navigate("/job/new")}
          >
            <Plus className="size-5 mr-2" />
            New Job
          </Button>
          {/* <Button
            variant="destructive"
            disabled={table.getSelectedRowModel().rows.length === 0 || isDeleteLoading}
            onClick={handleDeleteJob}
            className="dark:bg-red-400 dark:disabled:opacity-80"
          >
            {isDeleteLoading && <Loader2 className="size-4 animate-spin" />}
            <span>Delete</span>
          </Button> */}
        </div>
      </div>
    </div>
  );
}
