import { createSlice } from "@reduxjs/toolkit";

import { IStep } from "src/models";
import type { PayloadAction } from "@reduxjs/toolkit";

type StepValue =
  | "job_description"
  | "employer_details"
  | "assessment_criteria"
  | "candidate_resumes"
  | "screening_shortlist"
  | "interviews"
  | "reference_check";

const initialState: { steps: IStep[] } = {
  steps: [
    { label: "Job description", value: "job_description", isDone: false },
    { label: "Employer details", value: "employer_details", isDone: false },
    { label: "Assessment criteria", value: "assessment_criteria", isDone: false },
    { label: "Candidate resumes", value: "candidate_resumes", isDone: false },
    { label: "Screening Shortlist", value: "screening_shortlist", isDone: false },
    { label: "Interviews", value: "interviews", isDone: false },
    { label: "Reference check", value: "reference_check", isDone: false },
  ],
};

export const stepSlice = createSlice({
  name: "steps",
  initialState,
  reducers: {
    updateStep: (state, action: PayloadAction<StepValue>) => {
      const currentStepIndex = state.steps.findIndex((step) => step.value === action.payload);
      const newSteps = [...state.steps].map((step, index) => ({
        ...step,
        isDone: index <= currentStepIndex,
      }));

      console.log(newSteps);

      state.steps = newSteps;
    },
  },
});

export const { updateStep } = stepSlice.actions;
export default stepSlice.reducer;
