import { IReferenceCheck, ICandidateCheck, IRefereeCheck } from "src/models";
import { handleGlobalError } from "src/lib/utils";

import api, { axiosClient } from "../api";
import {
  IReferenceCheckToReferee,
  ISubmitResponseForReferenceCheck,
  // IUpdateReferenceCheckToCandidate,
} from "src/models/pay-load";

export const getShortlistCandidateReferenceCheck = async (jobId: string): Promise<IReferenceCheck[] | undefined> => {
  try {
    const res = await api.get(`/jobs/${jobId}/reference_check`);
    return res.data?.checks;
  } catch (error) {
    return undefined;
  }
};

export const sendReferenceCheckToCandidate = async (
  jobId: string,
  checkId: string,
  questions: string[],
  refereeCount: number,
  candidate: {
    first_name: string;
    last_name: string;
    email: string;
    mobile: string;
    profile_url: string;
  },
) => {
  try {
    const res = await api.post(`/jobs/${jobId}/reference_check/${checkId}/request_candidate`, {
      questions,
      referee_count: refereeCount,
      candidate,
    });
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

// export const updateReferenceCheckToCandidate = async (jobId: string, checkId: string, payload: IUpdateReferenceCheckToCandidate) => {
//   try {
//     const res = await api.
//   } catch (error) {
//     handleGlobalError(error);
//     return undefined;
//   }
// };

export const getCandidateInfoForReferenceCheck = async (
  jobId: string,
  checkId: string,
  token: string,
): Promise<ICandidateCheck | undefined> => {
  try {
    const res = await axiosClient.get(`/jobs/${jobId}/reference_check/${checkId}/candidate/${token}`);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getRefereeInfoForReferenceCheck = async (
  jobId: string,
  checkId: string,
  token: string,
): Promise<IRefereeCheck | undefined> => {
  try {
    const res = await axiosClient.get(`/jobs/${jobId}/reference_check/${checkId}/referee/${token}`);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const sendReferenceCheckToReferee = async (
  jobId: string,
  checkId: string,
  token: string,
  payload: IReferenceCheckToReferee,
) => {
  try {
    const res = await axiosClient.post(`/jobs/${jobId}/reference_check/${checkId}/candidate/${token}`, payload);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const submitResponseForReferenceCheck = async (
  jobId: string,
  checkId: string,
  token: string,
  payload: ISubmitResponseForReferenceCheck,
) => {
  try {
    const res = await axiosClient.post(`/jobs/${jobId}/reference_check/${checkId}/referee/${token}`, payload);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};
