import { combineReducers, configureStore } from "@reduxjs/toolkit";

import authReducer from "./features/auth";
import loadingReducer from "./features/loading";
import errorReducer from "./features/error";
import stepReducer from "./features/steps";

export const rootReducer = combineReducers({
  auth: authReducer,
  loading: loadingReducer,
  error: errorReducer,
  steps: stepReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
