import { useQuery } from "@tanstack/react-query";
import { IUser } from "src/models";

import { getUserProfile } from "src/services/user";

const handleGetUserProfile = async () => {
  const userProfile = await getUserProfile();
  return userProfile;
};

export const useUser = () => {
  return useQuery<IUser, Error>({
    queryKey: ["user_profile"],
    queryFn: async () => {
      const userProfile = await handleGetUserProfile();
      if (!userProfile) throw new Error("User profile not found");
      return userProfile;
    },
    staleTime: 20 * 60 * 1000,
  });
};
